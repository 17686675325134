import React from "react"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"

import styles from "./ProjectsPage.module.scss"

const Project = ({ project }) => {
  const { title, image, category, client, duration, description, url } = project

  return (
    <div key="cont" className={styles.cont}>
      <div>
        <h1>{title}</h1>
      </div>
      <div className={styles.content}>
        <div className={styles.left}>
          <Img fluid={image?.childImageSharp.fluid} />
        </div>
        <div className={styles.right}>
          <h2>Project information</h2>
          <ul className={styles.rightUl}>
            {category && (
              <li>
                <span>Category:</span>
                {category}
              </li>
            )}
            {client && (
              <li>
                <span>Client:</span>
                {client}
              </li>
            )}
            {duration && (
              <li>
                <span>Project duration:</span>
                {duration}
              </li>
            )}
            {url && (
              <li>
                <span>Project URL:</span>
                {url}
              </li>
            )}
          </ul>
          {description && (
            <ReactMarkdown
              source={description}
              className={styles.description}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Project

import React, { useMemo, useCallback } from "react"
import BannerAnim from "rc-banner-anim"
import queryString from "query-string"
import { navigate } from "@reach/router"

import Layout, { Body } from "../Shared/Layout"
import ContactUsForm from "../Shared/ContactUsForm"
import SEO from "../Shared/Seo"
import Project from "./Project"

import "rc-banner-anim/assets/index.css"
import styles from "./ProjectsPage.module.scss"

const { Element } = BannerAnim
const BgElement = Element.BgElement

const ProjectsPage = ({ projects, location }) => {
  const selectedIndex = useMemo(() => {
    const params = location.search ? queryString.parse(location.search) : {}
    const { project } = params

    const index = projects.indexOf(projects.find(p => p.slug === project))

    return index >= 0 ? index : 0
  }, [location.search, projects])

  const handleChange = useCallback(
    (x, currentIndex) => {
      if (x === "before") {
        navigate(`${location.pathname}?project=${projects[currentIndex]?.slug}`)
      }
    },
    [location, projects]
  )

  return (
    <Layout>
      <SEO
        title={`Projects - ${projects[selectedIndex]?.title}`}
        description="We deliver forward-thinking and innovative software solutions to customers around the world that partner with us for our technology consulting, core engineering and full-scale integration capabilities."
      />
      <div className={styles.wrap}>
        <BannerAnim
          initShow={selectedIndex}
          type="across"
          prefixCls={styles.banner}
          thumb={false}
          onChange={handleChange}
        >
          {projects.map(project => {
            return (
              <Element key={project.id} prefixCls={styles.item}>
                <BgElement key="bg" className={styles.bg} />
                <Project project={project} key={project.id} />
              </Element>
            )
          })}
        </BannerAnim>
      </div>
      <Body className={styles.contactsWrap}>
        <ContactUsForm />
      </Body>
    </Layout>
  )
}

export default ProjectsPage

import React from "react"
import { graphql } from "gatsby"
import Projects from "../components/ProjectsPage"

const ProjectsPage = ({ data, location }) => {
  const projects =
    data.allStrapiProject?.edges?.map(edge => ({ ...edge.node })) || []

  return <Projects projects={projects} location={location} />
}

export const pageQuery = graphql`
  query ProjectsQuery {
    allStrapiProject {
      edges {
        node {
          id
          title
          slug
          category
          client
          duration
          description
          url
          image {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default ProjectsPage
